<template>
  <div class="anonymous-page">
    <div class="anonymous-loading" v-if="isPageLoading">
      <ui-progress-circular color="black"/>
    </div>
    <div class="action-content" v-else>

      <div class="content-close" @click="close">
        <button>
          <i class="material-icons">close</i>
        </button>
      </div>

      <div class="content-title">
        <h1>Sécurisez vos <br> informations pour votre prochaine commande</h1>
      </div>

      <div class="content-form">
        <div class="form-group">
          <label>Votre email</label>
          <input type="email" :class="{'is-invalid': errors.some(e => e.error === 'email')}" v-model="email" name="email" class="form-control" placeholder="email@exemple.com">
          <small class="text-danger" v-if="errors.some(e => e.error === 'email')">{{errors.find(e => e.error === 'email').message}}</small>
        </div>
        <div class="form-group">
          <label>Choisissez votre mot de passe</label>
          <div class="password-content">
            <input :type="showPassword ? 'text' : 'password'" name="password" v-model="password" class="form-control" placeholder="">
            <button @click="showPassword = !showPassword"><i class="material-icons" v-if="!showPassword">visibility</i><i class="material-icons" v-else>visibility_off</i></button>
          </div>
          <small class="text-danger" v-if="errors.some(e => e.error === 'password')">{{errors.find(e => e.error === 'password').message}}</small>
        </div>
      </div>

      <div class="content-separator">
        <span>ou</span>
      </div>

      <div class="content-platform-auth">
        <button class="google-button" @click="migrateWithPlatform('google')">
          <img src="/img/google.svg" alt="">
          <span>Google</span>
        </button>
        <button class="facebook-button" @click="migrateWithPlatform('facebook')">
          <img src="/img/facebook.svg" alt="">
          <span>Facebook</span>
        </button>
      </div>

      <div class="content-platform-auth-error">
        <small v-if="errors.some(e => e.error === 'platform')">{{errors.find(e => e.error === 'platform').message}}</small>
      </div>

    </div>
    <div class="action-footer" v-if="!isPageLoading">
      <button class="action-button" @click="createAccount">C'est fini !</button>
    </div>
  </div>
</template>





<script>
import '../../scss/app_mobile.scss';
import '../../scss/Mobile/anonymous_pages.scss';
import {mapActions} from "vuex";
import Config from "@/config";

export default {
  name: "AnonymousMigration",
  data(){
    return {
      isLoading: false,
      isPageLoading: false,
      showPassword: false,
      password: null,
      email: null,
      errors: [],
      user: null
    }
  },
  methods: {
    ...mapActions(['migrateAnonymousAccount', 'getAccountAction']),

    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    validate(){
      this.errors = [];
      if(!this.email){
        this.errors.push({error: 'email', message: this.$t('errors.email_required')});
      }
      if(!this.validateEmail(this.email)){
        this.errors.push({error: 'email', message: this.$t('errors.email_not_valid')});
      }
      if(!this.password){
        this.errors.push({error: 'password', message: this.$t('errors.password_required')});
      }
      if(this.password.length < 6){
        this.errors.push({error: 'password', message: this.$t('errors.password_min_6_characters')});
      }
      return this.errors.length === 0;
    },

    async createAccount(){
      if(!this.validate()){
        return;
      }
      this.isLoading = true;
      await this.migrateAnonymousAccount({email: this.email, password: this.password})
          .then(() => {
            this.$router.push({name: 'ano_account_success', query: {redirect: this.$route.query.redirect}});
          })
          .catch((e) => {
            if(e === 'user_has_alreary_an_account'){
              this.errors.push({error: 'email', message: this.$t('errors.email_already_exist')});
            }
            this.isLoading = false;
          })

    },

    migrateWithPlatform(type){
      let redirectSuccess = encodeURI(location.origin + this.$router.resolve({name: 'ano_account_success', query: {redirect: this.$route.query.redirect}}).href);
      let redirectError = encodeURI(location.href + '&error=true');

      if(type === 'facebook'){
        return window.location.href = Config.login_url + '/login/facebook?redirect=' + redirectSuccess + '&redirectError='+ redirectError + '&account_migration=1&auth=' + localStorage.getItem('bearer');
      }
      if(type === 'google'){
        return window.location.href = Config.login_url + '/login/google?redirect=' + redirectSuccess + '&redirectError='+ redirectError + '&account_migration=1&auth=' + localStorage.getItem('bearer');
      }

    },

    close(){
      if(this.$route.query.redirect){
        localStorage.setItem('anonymous_account_conversion_denied', true);
        this.$router.push(this.$route.query.redirect);
      }
      else if(localStorage.getItem('anonymous_account_redirect')){
        this.$router.push(localStorage.getItem('anonymous_account_redirect'));
      }
      else{
        this.$router.push('/');
      }
    }
  },
  async mounted() {
    if(!this.$route.query.redirect){
      this.$router.back();
    }
    localStorage.setItem('anonymous_account_redirect', this.$route.query.redirect);

    if(this.$route.query.error){
      this.errors.push({error: 'platform', message: this.$t('errors.platform_connect_error')});
    }

    this.isPageLoading = true;
    this.user = await this.getAccountAction();
    this.isPageLoading = false;
    if(this.user.anonymous === false){
      this.$router.push({name: 'ano_account_success', query: {redirect: this.$route.query.redirect}});
    }
    if(this.user.anonymousEmail){
      this.email = this.user.anonymousEmail;
    }
  }
}
</script>
